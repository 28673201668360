import { types } from "@proj/static";
import http from "../utils/http";

export const getSpireConfigurations = async() => {
    let result = await (await http().get(`/spire`)).data;
    return result.data;
}

export const createSpireConfigurationService = async(data:types.spireTypes.ISpireConfig) => {
    let result = await (await http().post(`/spire`,data)).data;
    return result.data;
}

export const updateSpireConfigurationService = async(id:string,data:types.spireTypes.ISpireConfig) => {
    let result = await (await http().patch(`/spire/${id}`,data)).data;
    return result.data;
}

export const updateSpireUDFFields = async(id:string,data:types.spireTypes.ISpireUDFFields) => {
    let result = await (await http().patch(`/spire/${id}/udf`,data)).data;
    return result.data;
}

export const deleteSpireConfigurationService = async(id:string) => {
    let result = await (await http().delete(`/spire/${id}`)).data;
    return result.data;
}

// Abbreviation of SP -----> Shipping Partner

export const getSPConfigurations = async() => {
    let result = await (await http().get(`/shipping-partner`)).data;
    return result.data;
}

export const createSPConfigurationService = async(data:types.spireTypes.ISpireConfig) => {
    let result = await (await http().post(`/shipping-partner`,data)).data;
    return result.data;
}

export const updateSPConfigurationService = async(id:string,data:types.spireTypes.ISpireConfig) => {
    let result = await (await http().patch(`/shipping-partner/${id}`,data)).data;
    return result.data;
}

export const deleteSPConfigurationService = async(id:string) => {
    let result = await (await http().delete(`/shipping-partner/${id}`)).data;
    return result.data;
}

// end SP
