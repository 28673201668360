import React, { useCallback, useEffect, useState } from 'react';
import { Button, Space, Table, Modal, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ClientShippingProviderForm from '../../components/client/ShippingProviderForm';
import { types } from '@proj/static';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useParams } from 'react-router-dom';
import * as ClientActions from '../../redux/slices/client.slice';
import * as ClientServices from '../../services/client.services';



function ClientsShippingProviders() {

    const dispatch = useAppDispatch();
    const params = useParams();
    const [selectedShippingProvider,setSelectedShippingProvider] = useState<types.clientTypes.IClientShippingProvider|undefined>();
    const {shippingProviders} = useAppSelector(state=>state.client);
    const [clientId,setClientId] = useState<string>('');
    const [isAddShippingProviderFormVisible, setAddShippingProviderVisible] = useState(false);

    useEffect(()=>{
        let clientId:string|undefined = params.clientId;
        if(clientId && typeof clientId === 'string'){
            setClientId(clientId);
        }
    },[params])

    const getClientShippingProviders = useCallback(()=>{
        dispatch(ClientActions.getClientShippingProviders(clientId));
    },[clientId, dispatch])

    useEffect(()=>{
        if(clientId !== ""){
            getClientShippingProviders();   
        }
    },[clientId,getClientShippingProviders])

   
    const showAddShippingProviderModal = () => {
        setSelectedShippingProvider(undefined);
        setAddShippingProviderVisible(true);
    };

    const cancelAddShippingProviderModal = () => {
        setAddShippingProviderVisible(false);
    };

    const columns: ColumnsType<types.clientTypes.IClientShippingProvider> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Account number',
            dataIndex: 'accountNo',
            key: 'account_number',
        },
        {
            title: 'Margin amount',
            dataIndex: 'marginAmount',
            key: 'margin_amount',
        },
        {
            title: 'Methods',
            dataIndex: 'methods',
            key: 'methods',
            render:(_,record)=>{
                if(record.methods && Array.isArray(record.methods)){
                    return record.methods.join(", ")
                }
                return null
            }
        },
        {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <Button 
                        className="primarybtn" 
                        onClick={()=>onEdit(record)}
                    >
                            Edit
                    </Button>
                    <Button 
                        className="dangerbtn"
                        onClick={()=>onDelete(record)}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];
    
    const onEdit = (method:types.clientTypes.IClientShippingProvider) => {
        setSelectedShippingProvider(method);
        setAddShippingProviderVisible(true);
    }

    const onDelete = (method:types.clientTypes.IClientShippingProvider) => {
        Modal.confirm({
            title:"Are you sure to delete this shipping provider?",
            okType:"danger",
            okText:"Yes",
            onOk:()=>{
                if(method._id){
                    ClientServices.deleteClientShippingProvider(clientId,method._id.toString()).then(()=>{
                        message.success("Deleted");
                        getClientShippingProviders();
                    }).catch(()=>{
                        message.error("Error Occured");
                    })
                }
            }
        })
    }

    
    return (
        <section className="element-body">
            <div className="heading-block">
                <h2>Shipping Providers</h2>
                <Button className="primarybtn" onClick={showAddShippingProviderModal}>Add Shipping Provider</Button>
            </div>

            <div className="table-element">
                <Table columns={columns} dataSource={shippingProviders} pagination={false} />
            </div>

            <Modal
                title={`${selectedShippingProvider?"Edit":"Add"} Shipping Provider`}
                open={isAddShippingProviderFormVisible}
                onCancel={cancelAddShippingProviderModal}
                footer={[]}
                width={650}
                className="base-modal"
            >
                <ClientShippingProviderForm
                    clientId={clientId}
                    selectedShippingProvider={selectedShippingProvider}
                    onSuccess={()=>{
                        setSelectedShippingProvider(undefined);
                        cancelAddShippingProviderModal();
                        getClientShippingProviders();
                        Modal.success({
                            title:`Client shipping provider has been ${selectedShippingProvider?"updated":"created"} successfully`
                        })
                    }}
                />

            </Modal>

        </section>
    )
}

export default ClientsShippingProviders;
