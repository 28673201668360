import { Alert } from "antd"
import React, { ReactElement, ReactNode } from "react"

interface IErrorObject {
    [key:string]:{
        [key:string]:string[]
    }[]
}

function ShippingCreateError(obj:IErrorObject):ReactElement{
    return (
        <div>
        {
            Object.keys(obj).map(key=>{
                return (<div>
                    <h3>{key && key.toString().replace("_"," ").toUpperCase()}</h3>
                    {
                        obj[key] && typeof obj[key] === 'object' && obj[key].map(k=>{
                            if(k && typeof k === 'object'){
                                return Object.keys(k).map(subKey=>{
                                    return (
                                       <React.Fragment>
                                         <h5 style={{margin:"3px 0px"}}>{subKey && subKey !== ""?subKey.replace("_"," ").toUpperCase():subKey.toString()}</h5>
                                            {
                                                k[subKey].map(str=>{
                                                    return <Alert type="error" message={str.toString()} />
                                                })
                                            }
                                       </React.Fragment>
                                    )
                                })
                            }
                            else if(k && typeof k === 'string'){
                                return (
                                    <React.Fragment>
                                         <Alert type="error" message={k || "Error"} />
                                    </React.Fragment>
                                )
                            }
                        })
                    }
                </div>)
            })
        }
        </div>
    )
}

export default ShippingCreateError;