import { Navigate, Outlet } from 'react-router-dom';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import { useEffect, useState } from 'react';
import * as AuthActions from './redux/slices/auth.slice';

const ProtectedRoute = () => {
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const [isCheckingLoginStatus, setIsCheckinLoginStatus] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  
  useEffect(()=>{
    dispatch(AuthActions.isLoggedIn());
    setIsCheckinLoginStatus(false);
  },[])

  if(isCheckingLoginStatus){
    return (
      <p>Loading...</p>
    )
  }
  else if (!isLoggedIn) {
    return (
      <Navigate to={"/login"} />
    )
  }

  return <Outlet />
}
export default ProtectedRoute