import { types } from "@proj/static";
import http from "../utils/http";

export const getOrderDetails = async(orderId:string) => {
    let result = await (await http().get(`order/${orderId}`)).data;
    return result.data;
}

export const createShipping = async(data:types.shippingPartnerTypes.IShippingPartnerCreateShippingData) => {
    let result = await (await http().post(`order/shipping/create`,data)).data;
    return result.data;
}

export const updateOrderDetailsToSpire = async(orderId:string,data:types.spireTypes.IspireFields) => {
    let result = await (await http().patch(`order/${orderId}`,data)).data;
    return result.data;
}


export const resetSpireData = async(orderId:string) => {
    let result = await (await http().patch(`order/${orderId}/reset`,{})).data;
    return result.data;
}

interface IPurchaseLabel {
    orderId:string,
    rateId:string
}

export const purchaseLabelFromSP = async(data:IPurchaseLabel) => {
    let {
        orderId,rateId 
    } = data;
    let body = {
        rateId
    }
    let result = await (await http().post(`order/${orderId}/purchase`,body)).data;
    return result.data;
}