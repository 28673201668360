import { takeEvery,put } from "redux-saga/effects";
import {types} from '@proj/static';
import * as Configuration from '../slices/configuration.slice';
import * as configurationServices from '../../services/configurations.services';

function* getSpireConfigurations(){
    try{
        let result:types.spireTypes.ISpireConfig[] = yield(configurationServices.getSpireConfigurations());
        yield put(Configuration.getSpireConfigurationsSuccess(result));
    }
    catch(err){
        yield put(Configuration.getSpireConfigurationsFailed({
            error:err,
            message:"Error Occured"
        }));
    }
}

function* getShippingPartnerConfigurations(){
    try{
        let result:types.shippingPartnerTypes.IShippingPartner[] = yield(configurationServices.getSPConfigurations());
        yield put(Configuration.getShippingPartnerConfigurationsSuccess(result));
    }
    catch(err){
        yield put(Configuration.getShippingPartnerConfigurationsFailed({
            error:err,
            message:"Error Occured"
        }));
    }
}

function* configurationSaga(){
    yield takeEvery(Configuration.getSpireConfigurations.type,getSpireConfigurations);  
    yield takeEvery(Configuration.getShippingPartnerConfigurations.type, getShippingPartnerConfigurations);
}

export default configurationSaga;