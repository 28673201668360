import { types } from "@proj/static";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import {uuid} from 'uuidv4';
import dayjs from 'dayjs';

type TInitialState = {
    orderDetails:types.spireTypes.ISpireOrder|undefined,
    isFetchingOrderDetails:boolean,
    selectedBoxes:types.orderTypes.TBox[],
    shippingPartnerResponse?:types.shippingPartnerTypes.IShippingPartnerResponse,
    isShippingPartnerResponseReady:boolean,
    isProceedForShipping:boolean,
    shippingDate?:Date,
    selectedMethod?:string,
    trackingNumber?:string,
    shippingLabel?:string,
    itemBoxes?:types.orderTypes.IItemBox[],
    customBoxes:types.orderTypes.ICustomBox[]
}

const intialState:TInitialState = {
    orderDetails:undefined,
    isFetchingOrderDetails:false,
    selectedBoxes:[],
    isShippingPartnerResponseReady:false,
    isProceedForShipping:false,
    itemBoxes:[],
    customBoxes:[]
}

export const OrderSlice = createSlice({
    name:"Order",
    initialState:intialState,
    reducers:{
        getOrderDetails:(state,action:PayloadAction<string>)=>{
            return {
                ...state,
                isFetchingOrderDetails:true
            }
        },
        getOrderDetailsSuccess:(state,action:PayloadAction<types.spireTypes.ISpireOrder>)=>{
            let newState = {
                ...state,
                orderDetails:action.payload,
                isFetchingOrderDetails:false
            }
            
            newState.shippingPartnerResponse = action.payload.spResponse as types.shippingPartnerTypes.IShippingPartnerResponse
            newState.trackingNumber = action.payload.shippingTrackingNo;
            newState.shippingLabel = action.payload.shippingLabel;
            newState.shippingDate = dayjs(action.payload.shippingDate).toDate()
            newState.selectedMethod = action.payload.spSelectedRateId;
            newState.selectedBoxes = action.payload.boxConfigs || [];
            newState.itemBoxes = action.payload.itemBoxes || [];
            newState.customBoxes = action.payload.customBoxes || [];
            
            return newState
        },
        getOrderDetailsFailed:(state)=>{
            return {
                ...state,
                isFetchingOrderDetails:false
            }
        },

        resetOrderDetails:(state)=>{
            return {
                ...state,
                orderDetails:undefined,
                selectedBoxes:[],
                trackingNumber:undefined,
                shippingLabel:undefined,
                selectedMethod:undefined,
                shippingDate:undefined,
                isProceedForShipping:false,
                isShippingPartnerResponseReady:false
            }
        },
        addBox:(state,action:PayloadAction<string>)=>{
            let boxes = [...state.selectedBoxes];
            boxes.push({
                id:action.payload,
                uuid:_.uniqueId(),
                weightType:"manual",
                weight:0,
                weightUsed:0,
                volumeUsed:0,
                weightUnit:"kg"
            })
            return {
                ...state,
                selectedBoxes:boxes
            }
        },
        deleteBox:(state,action:PayloadAction<number>) => {
            return {
                ...state,
                selectedBoxes:state.selectedBoxes.filter((b,i)=> i !== action.payload)
            }
        },
        createShipping:(state,action:PayloadAction<types.shippingPartnerTypes.IShippingPartnerCreateShippingData>)=>{
            return {
                ...state,
                isShippingPartnerResponseReady:false,
                isProceedForShipping:true
            }
        },
        createShippingSuccess:(state,action:PayloadAction<types.shippingPartnerTypes.IShippingPartnerResponse>)=>{
            return {
                ...state,
                shippingPartnerResponse:action.payload,
                isShippingPartnerResponseReady:true,
                isProceedForShipping:false
            }
        },
        createShippingFailed:(state)=>{
            return {
                ...state,
                isShippingPartnerResponseReady:false,
                isProceedForShipping:false
            }
        },
        setSelectedBoxes:(state,action:PayloadAction<types.orderTypes.TBox[]>)=>{
            return {
                ...state,
                selectedBoxes:action.payload
            }
        },
        onSelectMethodId:(state,action:PayloadAction<string>)=>{
            return {
                ...state,
                selectedMethod:action.payload
            }
        },
        setShippingDate:(state,action:PayloadAction<Date>)=>{
            return {
                ...state,
                shippingDate:action.payload
            }
        },
        assignItemToBox:(state,action:PayloadAction<{item:types.spireTypes.TItem,itemNo:number,boxId:string}>)=>{
            let itemBoxes = state.itemBoxes?_.cloneDeep(state.itemBoxes):[];
            let exitingItemBoxIndex = itemBoxes.findIndex(i=>i.itemId.toString() === action.payload.item.id.toString() && i.itemNo === action.payload.itemNo);
            if(exitingItemBoxIndex > -1){
                itemBoxes[exitingItemBoxIndex].boxId = action.payload.boxId;
                itemBoxes[exitingItemBoxIndex].customBox = false;
            }
            else{
                itemBoxes.push({
                    itemId:action.payload.item.id.toString(),
                    partNo:action.payload.item.partNo,
                    totalQty:action.payload.item.qty?parseInt(action.payload.item.qty):1,
                    boxId:action.payload.boxId,
                    itemNo:action.payload.itemNo,
                    customBox:false
                })
            }
            return {
                ...state,
                itemBoxes
            }
        },
        purchaseLabel:(state,action:PayloadAction<{orderId:string, rateId:string}>) => {
            return {
                ...state
            }
        },
        addCustomBox:(state,action:PayloadAction<types.orderTypes.ICustomBox>) => {
            let newBox = action.payload;
            newBox.canBeModified = true;
            newBox.id = `custom-${_.uniqueId()}`;
            let customBoxes = [
                ...state.customBoxes,
                newBox
            ]
            return {
                ...state,
                customBoxes
            }
        },

        updateCustomBox:(state,action:PayloadAction<{index:number,data:types.orderTypes.ICustomBox}>) => {
            let customBoxes = [
                ...state.customBoxes
            ]
            customBoxes[action.payload.index] = {
                ...customBoxes[action.payload.index],
                ...action.payload.data
            };
            return {
                ...state,
                customBoxes
            }
        },

        deleteCustomBox:(state, action:PayloadAction<number>) => {
            let customBoxes = [...state.customBoxes].filter((box,i)=>i !== action.payload);
            return {
                ...state,
                customBoxes
            }
        },

        assignItemToCustomBox:(state,action:PayloadAction<{item:types.spireTypes.TItem,itemNo:number,boxId:string}>) => {
            let itemBoxes = state.itemBoxes?_.cloneDeep(state.itemBoxes):[];
            let exitingItemBoxIndex = itemBoxes.findIndex(i=>i.itemId.toString() === action.payload.item.id.toString() && i.itemNo === action.payload.itemNo);
            if(exitingItemBoxIndex > -1){
                itemBoxes[exitingItemBoxIndex].boxId = action.payload.boxId;
                itemBoxes[exitingItemBoxIndex].customBox = true;
            }
            else{
                itemBoxes.push({
                    itemId:action.payload.item.id,
                    partNo:action.payload.item.partNo,
                    totalQty:action.payload.item.qty?parseInt(action.payload.item.qty):1,
                    boxId:action.payload.boxId,
                    itemNo:action.payload.itemNo,
                    customBox:true
                })
            }
            return {
                ...state,
                itemBoxes
            }
        },

        onChangeSelectedBox:(state,action:PayloadAction<{boxUUID:string,target:keyof types.orderTypes.TBox, value:boolean|number|string}>) => {
            let selectedBoxIndex = state.selectedBoxes.findIndex(sb=>sb.uuid === action.payload.boxUUID);
            let selectedBoxes = _.cloneDeep(state.selectedBoxes);
            let {target,value} = action.payload;
            switch (target) {
                case "weightType":
                    if(value && typeof value === 'string' && ["manual","automatic"].includes(value)){
                        selectedBoxes[selectedBoxIndex]["weightType"] = value as "manual"|"automatic";
                    }
                    break;
                case "weight":
                    if(value && typeof value === "number"){
                        selectedBoxes[selectedBoxIndex]["weight"] = value;
                    }
                    break;
                case "weightUnit":
                    if(value && typeof value === "string" && ["kg","lb"].includes(value)){
                        selectedBoxes[selectedBoxIndex]["weightUnit"] = value as "lb"|"kg";
                    }
                    break;
                default:
                    break;
            }
            return {
                ...state,
                selectedBoxes
            }
        },

        onChangeCustomBox:(state,action:PayloadAction<{id:string,target:keyof types.orderTypes.TBox, value:boolean|number|string}>) => {
            let selectedBoxIndex = state.customBoxes.findIndex(sb=>sb.id === action.payload.id);
            let customBoxes = _.cloneDeep(state.customBoxes);
            let {target,value} = action.payload;
            switch (target) {
                case "weightType":
                    if(value && typeof value === 'string' && ["manual","automatic"].includes(value)){
                        customBoxes[selectedBoxIndex]["weightType"] = value as "manual"|"automatic";
                    }
                    break;
                case "weight":
                    if(value && typeof value === "number"){
                        customBoxes[selectedBoxIndex]["weight"] = value;
                    }
                    break;
                case "weightUnit":
                    if(value && typeof value === "string" && ["kg","lb"].includes(value)){
                        customBoxes[selectedBoxIndex]["weightUnit"] = value as "lb"|"kg";
                    }
                    break;
                default:
                    break;
            }
            return {
                ...state,
                customBoxes
            }
        }
    }
})

export const {
    getOrderDetails,
    getOrderDetailsSuccess,
    resetOrderDetails,
    addBox,
    createShipping,
    setSelectedBoxes,
    createShippingSuccess,
    deleteBox,
    onSelectMethodId,
    setShippingDate,
    assignItemToBox,
    getOrderDetailsFailed,
    createShippingFailed,
    purchaseLabel,
    addCustomBox,
    updateCustomBox,
    deleteCustomBox,
    assignItemToCustomBox,
    onChangeSelectedBox,
    onChangeCustomBox
} = OrderSlice.actions;
export default OrderSlice.reducer;