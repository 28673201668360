import { types } from "@proj/static";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type IState = {
    spireConfigs:types.spireTypes.ISpireConfig[],
    shippingPartnerConfigs:types.shippingPartnerTypes.IShippingPartner[]
}

const initalState:IState = {
    spireConfigs:[],
    shippingPartnerConfigs:[]
}


export const configurationSlice = createSlice({
    name:"configuration",
    initialState:initalState,
    reducers:{
        getSpireConfigurations:(state)=>{
            return {
                ...state
            }
        },
        getSpireConfigurationsSuccess:(state,action:PayloadAction<types.spireTypes.ISpireConfig[]>)=>{
            return {
                ...state,
                spireConfigs:action.payload
            }
        },
        getSpireConfigurationsFailed:(state,action:PayloadAction<types.commonTypes.HTTP_ERROR>)=>{
            return {
                ...state
            }
        },
        getShippingPartnerConfigurations:(state)=>{
            return {
                ...state
            }
        },
        getShippingPartnerConfigurationsSuccess:(state,action:PayloadAction<types.shippingPartnerTypes.IShippingPartner[]>)=>{
            return {
                ...state,
                shippingPartnerConfigs:action.payload
            }
        },
        getShippingPartnerConfigurationsFailed:(state,action:PayloadAction<types.commonTypes.HTTP_ERROR>)=>{
            return {
                ...state
            }
        }
    }
})

export const {
    getSpireConfigurations,
    getSpireConfigurationsSuccess,
    getSpireConfigurationsFailed,
    getShippingPartnerConfigurations,
    getShippingPartnerConfigurationsSuccess,
    getShippingPartnerConfigurationsFailed
} = configurationSlice.actions;

export default configurationSlice.reducer;