import { takeEvery,put } from "redux-saga/effects";
import * as Order from '../slices/order.slice';
import {types} from '@proj/static';
import * as orderServices from '../../services/order.services';
import { getError } from "../../utils/error";
import * as Common from '../slices/common.slice';


function* getOrderDetails(action:types.commonTypes.SAGA_ACTION){
    try{
        let result:types.spireTypes.ISpireOrder = yield(orderServices.getOrderDetails(action.payload));
        yield put(Order.getOrderDetailsSuccess(result));
    }
    catch(err:unknown){
        let error = getError(err);
        yield put(Order.getOrderDetailsFailed());
        yield put(Common.displayError({
            key:Order.getOrderDetails.type,
            error
        }));
    }
}

function* createShipping(action:types.commonTypes.SAGA_ACTION){
    try{
        let result:types.shippingPartnerTypes.IShippingPartnerResponse = yield(orderServices.createShipping(action.payload));
        yield put(Order.createShippingSuccess(result));
    }
    catch(err){
        let error = getError(err);
        yield put(Order.createShippingFailed());
        yield put(Common.displayError({
            key:Order.createShipping.type,
            error
        }));
    }
}

function* purchaseLabel(action:types.commonTypes.SAGA_ACTION){
    try{
        let result:types.shippingPartnerTypes.IShippingPartnerResponse = yield(orderServices.purchaseLabelFromSP(action.payload));
        yield put(Order.createShippingSuccess(result));
    }
    catch(err){
        let error = getError(err);
        yield put(Order.createShippingFailed());
        yield put(Common.displayError({
            key:Order.createShipping.type,
            error
        }));
    }
}


function* clientSaga(){
    yield takeEvery(Order.getOrderDetails.type,getOrderDetails);
    yield takeEvery(Order.createShipping.type,createShipping);
    yield takeEvery(Order.purchaseLabel.type,purchaseLabel);
}

export default clientSaga;