import { types } from "@proj/static";
import http from "../utils/http";

export const getUsers = async() => {
    let result = await (await http().get(`/user`)).data;
    return result.data;
}


export const getUserRoles = async() => {
    let result = await (await http().get(`/user/roles`)).data;
    return result.data;
}

export const createUser = async(data:types.userTypes.IUser) => {
    let result = await (await http().post(`user`,data)).data;
    return result.data;
}

export const updateUser = async(id:string,data:types.userTypes.IUser) => {
    let result = await (await http().patch(`user/${id}`,data)).data;
    return result.data;
}

export const deleteUser = async(id:string) => {
    let result = await (await http().delete(`user/${id}`)).data;
    return result.data;
}


export const createUserRole = async(data:types.userTypes.IUserRole) => {
    let result = await (await http().post(`user/role`,data)).data;
    return result.data;
}

export const updateUserRole = async(id:string,data:types.userTypes.IUserRole) => {
    let result = await (await http().patch(`user/role/${id}`,data)).data;
    return result.data;
}

export const deleteUserRole = async(id:string) => {
    let result = await (await http().delete(`user/role/${id}`)).data;
    return result.data;
}