import { takeEvery,put } from "redux-saga/effects";
import * as Client from '../slices/client.slice';
import {types} from '@proj/static';
import * as clientServices from '../../services/client.services';

function* getClients(){
    try{
        let result:types.clientTypes.IClientWithCount[] = yield(clientServices.getClients());
        yield put(Client.getClientsSuccess(result));
    }
    catch(err){
        yield put(Client.getClientsFailed({
            error:err,
            message:"Error Occured"
        }))
    }
}

function* getClientShippingProviders(action:types.commonTypes.SAGA_ACTION){
    try{
        let result:types.clientTypes.IClientShippingProvider[] = yield(clientServices.getClientShippingProviders(action.payload));
        yield put(Client.getClientShippingProvidersSuccess(result));
    }
    catch(err){
        yield put(Client.getClientShippingProvidersFailed({
            error:err,
            message:"Error Occured"
        }))
    }
}

function* getClientShippingBoxes(action:types.commonTypes.SAGA_ACTION){
    try{
        let result:types.clientTypes.IClientShippingBox[] = yield(clientServices.getClientShippingBoxes(action.payload));
        yield put(Client.getClientShippingBoxesSuccess(result));
    }
    catch(err){
        yield put(Client.getClientShippingBoxesFailed({
            error:err,
            message:"Error Occured"
        }))
    }
}


function* clientSaga(){
    yield takeEvery(Client.getClients.type,getClients);
    yield takeEvery(Client.getClientShippingProviders.type,getClientShippingProviders);
    yield takeEvery(Client.getClientShippingBoxes.type,getClientShippingBoxes);
}

export default clientSaga;